import React, {useEffect} from 'react';
import './Privacy.css';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <section className="games-section">
            <div className="privacy-list">
                <div className="privacy-policy-title">
                    <h1>Privacy Policy</h1>
                </div>
                <div className="privacy-policy">
                    <div>
                        <h2>Rising Out Privacy Policy Executive Summary</h2>
                    </div>
                    <p>Privacy policy last updated 28 September 2024.</p>
                    <ul>
                        <li>We do not collect any personally identifiable information</li>
                        <li>All information we collect is solely for the proper functioning of our games and will never
                            be sold or shared with others
                        </li>
                        <li>Our third-party partners may also collect information to deliver advertisements. Below, you
                            will find links to these partners and their respective privacy policies.
                        </li>
                    </ul>
                    <hr className="default"/>

                    <p>At Rising Out (SIREN: 932 301 179 R.C.S. Toulon) (hereinafter referred to as ‘We’ or ‘Our’),
                        we
                        value your comments, suggestions, and feedback.</p>
                    <p>This Privacy Policy outlines how we collect, use, and disclose “personal information” and
                        other “non-personal information” we may gather about you in relation to all the games we
                        have created and developed (“Games,” which includes all versions and ports on any platform,
                        as well as Our Website).</p>

                    <h3>This policy informs you about:</h3>

                    <ul>
                        <li>What information (commonly referred to as ‘data’) is collected from you by Us;</li>
                        <li>How your information is utilized;</li>
                        <li>With whom your information may be shared;</li>
                    </ul>

                    <p>We will comply with all relevant and applicable legislative requirements, and, in the event
                        of any inconsistency, the legislative requirements will override the provisions of this
                        document.</p>

                    <h3>Children's privacy</h3>
                    <p>Our Service does not address anyone under the age of 16 (“Children”). When we collect
                        personal information, we do not know the age of our players. We do not knowingly collect
                        personally identifiable information from children under 16. If you are a parent or guardian
                        and you are aware that your Children have provided us with Personal Information, please
                        contact us. If we become aware that we have collected Personal Information from a child
                        under age 16 without verification of parental consent, we will take steps to remove that
                        information from our servers.</p>


                    <h3>How do we use the data we collect?</h3>
                    <p>No matter how we come to collect your data, we will always treat it in accordance with this
                        document, and any applicable legislation.</p>
                    <h3>What kinds of data are there, and what do we collect?</h3>
                    <p>Data collected can be personal to you or non-personal, and sometimes data that doesn’t appear
                        to be personal can become personal if certain other facts are learned. </p>
                    <p>Data can be collected in three ways:</p>
                    <ul>
                        <li>1. Data collected automatically;</li>
                        <li>2. Data you provide us; and</li>
                        <li>3. Data collected by our partners.</li>
                    </ul>
                    <p>The basis for collecting this data is “legitimate interest” under GDPR (Global Data Protection
                        Regulation) and “to support internal operations of the game” under COPPA (Children's Online
                        Privacy Protection Act). </p>
                    <p>Any data collected is done solely for internal uses that are reasonably and directly related to
                        your relationship with Us, in accordance with the California Consumer Privacy Act (CCPA).</p>
                    <h3>1. Data collected automatically</h3>
                    <p>Certain data is collected automatically. This includes:</p>
                    <ul>
                        <li>Your device Identification number, and data about your device, such as device name and
                            operating system, and language;
                        </li>
                        <li>Data about your use of the Service, by which we mean gameplay data that we us for data
                            saving/loading game files.
                        </li>
                    </ul>
                    <h4>What is Personal Information?</h4>
                    <p>‘Personal Information’ is information that identifies you as an individual. The Game will
                        automatically collect your device identification number, solely so that the Game can operate
                        effectively on your device. This information is anonymised prior to being viewed by Us, and
                        never sold or shared. </p>
                    <h4>What about Sensitive Information? </h4>
                    <p>Sensitive information includes information about such things as an individual’s racial or ethnic
                        origin, political opinions, membership of a political association, religious or philosophical
                        beliefs, membership of a professional body, criminal records or health information.</p>
                    <p>We will not attempt to obtain or record any sensitive information.</p>

                    <h3>3. Data we collect from our third-party partners.</h3>
                    <p>Collection of other ‘Non-Personal’ Information</p>
                    <p>Some non-personal information may be gathered while you use Our Game. Non-personal information is
                        general, non-identifiable and anonymous information that is gathered for statistical, analytical
                        and research purposes such as the improvement of our games (for example, bug fixes).</p>
                    <p>Our apps may contain APIs from other third parties. These are third party services, and we do not
                        control how they interact with you and you should ensure that you consent to the terms of use
                        and privacy policies offered by the third-party services before providing them with your
                        information.</p>
                    <p>Our third-party partners, and their privacy policies are as follows:</p>
                    <ul>
                        <li>Google AdMob: <a
                            href="https://support.google.com/admob/answer/6128543?hl=en">https://support.google.com/admob/answer/6128543?hl=en</a>
                        </li>
                        <li>Unity Ads: <a
                            href="http://unity3d.com/legal/privacy-policy">http://unity3d.com/legal/privacy-policy</a>
                        </li>
                        <li>Google LLC (Firebase)
                            <a href="https://firebase.google.com/support/privacy/">https://firebase.google.com/support/privacy/</a>
                        </li>
                        <li>Unity Technologies
                            <a href="https://unity3d.com/legal/privacy-policy">https://unity3d.com/legal/privacy-policy</a>
                        </li>
                        <li>Apple Inc. (Apple Itunes Connect)
                            <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a>
                        </li>
                    </ul>

                    <h3>In-App Purchases</h3>
                    <p>Our Games enable in-app purchases. We do not process payments for in-app purchases nor have
                        access to your credit card information.</p>

                    <p>Payment transactions for in-app purchases are completed through the app store via your App Store
                        account or Google Play account. Any post-purchase processes are controlled by the Apple and
                        Google stores and are subject to specific terms of such Apple and Google stores. Please review
                        privacy policy and terms of service of your Apple and Google stores.</p>


                    <h3>Policy Updates</h3>
                    <p>This Policy may change from time to time. The most current version of our Privacy Policy can be
                        found at: </p>
                    <a href="https://www.rising-out.com/privacy">www.rising-out.com/privacy</a>
                    <p>We will always keep this up to date so that you know what information we will collect from you,
                        how we may use it, and when we may disclose it.</p>
                    <h3>Complaints</h3>
                    <p>You can lodge a complaint with us about any breach of our Privacy Policy at the address
                        below.</p>

                    <h3>Privacy Policy Contact Information</h3>
                    <p>If you have any questions about our Privacy Policy, please contact us
                        at: <span>contact@rising-out.com</span></p>
                    <p>Rising Out ©2024</p>
                </div>
            </div>
        </section>
    )
        ;
};

export default PrivacyPolicy;
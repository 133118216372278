import React, {useState} from 'react';
import {NavLink, Link} from 'react-router-dom';
import './Navbar.css';
import {motion, AnimatePresence} from 'framer-motion';

function Navbar() {
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };
    const list = {
        open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
                delayChildren: 0.3,
                staggerChildren: 0.05
            }
        },
        closed: {
            clipPath: "inset(10% 0% 90% 100% round 10px)",
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
            }
        }
    }

    const item = {
        open: {
            opacity: 1,
            y: 0,
            transition: {type: "spring", stiffness: 300, damping: 24}
        },
        closed: {opacity: 0, y: 20, transition: {duration: 0.2}}
    }

    return (
        <div>
            {/* Navbar pour les grands écrans (version web) */}
            <nav className="navbar">
                <div className="logo logo-div">
                    <Link className="rising-out-link" to="/">
                        <h1>Rising Out</h1>
                    </Link>
                </div>

                {/*<ul className="nav-links">
                    <li>
                        <NavLink
                            to="/"
                            className={({isActive}) => (isActive ? 'active' : '')}
                        >
                            Accueil
                            <motion.div
                                className="underline"
                                layoutId="underline"
                                transition={{duration: 0.3}}
                            />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/blog"
                            className={({isActive}) => (isActive ? 'active' : '')}
                        >
                            Blog
                            <motion.div
                                className="underline"
                                layoutId="underline"
                                transition={{duration: 0.3}}
                            />
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/mentionlegal"
                            className={({isActive}) => (isActive ? 'active' : '')}
                        >
                            Mentions Légales
                            <motion.div
                                className="underline"
                                layoutId="underline"
                                transition={{duration: 0.3}}
                            />
                        </NavLink>
                    </li>
                </ul>*/}

                {/* Bouton de menu pour mobile */}
                {/*<motion.button className="button nav-icon" onClick={toggleModal}
                               whileTap={{scale: 0.8}}>
                    <img src="/images/nav-icon-v2.png" className="img-close"/>
                </motion.button>*/}
            </nav>

            {/* Modal pour les écrans mobiles */}
            {
                <motion.div
                    className="modal"
                    initial="closed"
                    animate={isModalOpen ? "open" : "closed"}
                    variants={list}
                    style={{pointerEvents: isModalOpen ? "auto" : "none"}}
                >
                    <motion.ul

                    >
                        <motion.li variants={item}><Link className="text-color-modal" to="/" onClick={toggleModal}>Accueil</Link></motion.li>
                        <motion.li variants={item}><Link className="text-color-modal" to="/blog" onClick={toggleModal}>Blog</Link></motion.li>
                        <motion.li variants={item}><Link className="text-color-modal" to="/mentionlegal" onClick={toggleModal}>Mentions
                            Légales</Link></motion.li>
                    </motion.ul>
                </motion.div>
            }
        </div>
    );
}

export default Navbar;
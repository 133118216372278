import React from 'react';
import ReactDOM from 'react-dom/client'; // Assure-toi d'importer depuis 'react-dom/client'

import './index.css';
import App from './App.js';

const root = ReactDOM.createRoot(document.getElementById('root')); // Crée une racine
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
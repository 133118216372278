import React, { useState } from 'react';
import Cookies from 'js-cookie';
import './CookieBanner.css';

const CookieModal = ({ setShowModal }) => {
    const [preferences, setPreferences] = useState({
        necessary: true,
        analytics: false,
        marketing: false
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setPreferences(prev => ({ ...prev, [name]: checked }));
    };

    const acceptCookies = () => {
        Cookies.set('userConsent', JSON.stringify(preferences), { expires: 365 });
        setShowModal(false);
        console.log('Cookies accepted', preferences);
    };

    const refuseCookies = () => {
        const cookieData = {
            necessary: true, // Toujours nécessaire
            analytics: false,
            marketing: false
        };
        Cookies.set('userConsent', JSON.stringify(cookieData), { expires: 365 });
        setShowModal(false);
        console.log('Cookies refused', cookieData);
    };

    return (
        <div className="cookie-modal">
            <div className="cookie-content">
                <p>We use cookies to enhance your experience. Please select your preferences:</p>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="analytics"
                            checked={preferences.analytics}
                            onChange={handleCheckboxChange}
                        />
                        Analytics Cookies
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="marketing"
                            checked={preferences.marketing}
                            onChange={handleCheckboxChange}
                        />
                        Marketing Cookies
                    </label>
                </div>
                <div className="cookie-buttons">
                    <button className="cookie-button accept" onClick={acceptCookies}>Accept</button>
                    <button className="cookie-button refuse" onClick={refuseCookies}>Refuse</button>
                </div>
            </div>
        </div>
    );
};

export default CookieModal;
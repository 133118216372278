import React from 'react';
import "./NotFound.css";

const NotFound = () => {
    return (
        <div className="not-found">
            <h1 className="text-line">Ouuupsss, cette page n'existe pas !</h1>
        </div>);
};

export default NotFound;
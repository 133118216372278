import React, {useEffect} from 'react';
import './LandingPage.css'; // Assure-toi de créer ce fichier CSS
import {motion} from 'framer-motion';

function LandingPage() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll vers le haut
    }, []); // Le tableau vide signifie que cela ne se produit qu'une fois, au montage du composant


    return (
        <div className="landing-page">
            {/* Section de démarrage */}
            <section className="hero-section">
                <h1 className="text-color">Bienvenue sur notre Studio de Jeux</h1>
                <img src="/images/viking-run.png" alt="Hero" className="hero-image"/>
            </section>

            {/* Section pour les jeux */}
            <section className="games-section">
                <h1 className="text-color">Nos Jeux</h1>
                <div className="games-list">
                    {/* Ajoute ici des cartes ou des éléments pour chaque jeu */}
                    <motion.div whileHover={{scale: 1.1}} className="game-item">
                        <img src="/images/loppy-fish.png" className="game-image"/>
                    </motion.div>
                    <motion.div whileHover={{scale: 1.1}} className="game-item">
                        <img src="/images/viking-escapes.png" className="game-image"/>
                    </motion.div>
                </div>
            </section>

            {/* Section pour les statistiques */}
            {/*<section className="stats-section">
                <h2>Statistiques de l'application</h2>
                <div className="stats">
                    <div className="stat-item">
                        <h3>Nombre de téléchargements</h3>
                        <p>1,000,000+</p>
                    </div>
                    <div className="stat-item">
                        <h3>Nombre de notes</h3>
                        <p>50,000+</p>
                    </div>
                </div>
            </section>*/}
        </div>
    );
}

export default LandingPage;

import React, {useEffect} from 'react';
import './MentionLegal.css';

const MentionLegal = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll vers le haut
    }, []); // Le tableau vide signifie que cela ne se produit qu'une fois, au montage du composant

    return (
        <section className="games-section">
            <div className="games-list">
                <div className="mention-legales-title">
                    <h1>Mentions Légales</h1>
                </div>
                {/* Ajoute ici des cartes ou des éléments pour chaque jeu */}
                <div className="mentions-legales">
                    <h2>Informations sur la société</h2>
                    <p>
                        Le site internet <a href="https://www.rising-out.com">www.rising-out.com</a> (ci-après dénommé
                        le « Site ») est la propriété de la société <strong>Rising Out SASU</strong>, société par
                        actions simplifiée à associé unique, au capital social de 500 euros, immatriculée au Registre du
                        Commerce et des Sociétés de Toulon sous le numéro <strong>932 301 179</strong>, dont le siège
                        social est situé au <strong>261 Rue Jean Moulin, 83270 Saint-Cyr-sur-Mer,
                        France</strong> (ci-après « Rising Out »).
                    </p>
                    <p>
                        <strong>Adresse e-mail</strong> : <a
                        href="mailto:contact@rising-out.com">contact@rising-out.com</a>
                    </p>
                    <p>
                        <strong>Numéro d’identification fiscale</strong> : 93230117900012
                    </p>
                    <p>
                        <strong>Numéro de TVA Intracommunautaire</strong> : FR23932301179
                    </p>

                    <h2>Responsable de la publication</h2>
                    <p>
                        Le responsable de la publication du Site est <strong>M. Boris Roussel</strong>.
                    </p>

                    <h2>Hébergement du site</h2>
                    <p>
                        Le site est hébergé par <strong>OVH</strong>, une société par actions simplifiée au capital de
                        10 174 560 euros, dont le siège social est situé au <strong>2 rue Kellermann, 59100 Roubaix,
                        France</strong>.
                    </p>
                    <p>
                        <strong>SIRET</strong> : 424 761 419 00045
                    </p>
                    <p>
                        <strong>Numéro de TVA Intracommunautaire</strong> : FR22424761419
                    </p>
                    <p>
                        <strong>Téléphone</strong> : <a href="tel:+33972101007">+33 (0) 9 72 10 10 07</a>
                    </p>
                    <p>
                        <strong>Site web</strong> : <a href="https://www.ovh.com" target="_blank"
                                                       rel="noopener noreferrer">www.ovh.com</a>
                    </p>
                    <h2>Conditions d'utilisation</h2>
                    <p>
                        Pour une navigation optimale sur le site www.rising-out.com, nous vous recommandons d’utiliser
                        des navigateurs modernes tels que Safari, Firefox, Google Chrome, ou tout autre navigateur à
                        jour. Rising Out SASU s’efforce de mettre en œuvre tous les moyens disponibles pour garantir la
                        fiabilité et l’actualité des informations publiées sur son site internet. Cependant, des erreurs
                        ou omissions peuvent se produire. Il est donc recommandé aux utilisateurs de vérifier
                        l’exactitude des informations en contactant Boris Roussel à l’adresse suivante :
                        contact@rising-out.com, et de signaler toute modification jugée nécessaire. Rising Out décline
                        toute responsabilité quant à l’utilisation des informations fournies sur son site, ainsi que
                        pour tout dommage direct ou indirect pouvant en résulter.
                    </p>
                </div>
            </div>
        </section>);

};

export default MentionLegal;